<template>
<div class="card-box-full" style="min-height: 500px">
  <div class="card-box-header">
    <table width="100%">
      <tr>
        <td align="left" width="50%">
          <span v-if="interval == '1h'">Last Hour Stats</span>
          <span v-if="interval == '12h'">12 Hour Stats</span>
          <span v-if="interval == '1d'">24 Hour Stats</span>
          <span v-if="interval == '7d'">7 Day Stats</span>
          <span v-if="interval == '30d'">30 Day Stats</span>
          <span v-if="interval == '1y'">1 Year Stats</span>
        </td>
        <td width="50%" align="right" style="font-size: 13px;">
          <span v-if="interval != '1h'" class="interval-link" @click="clickedInterval('1h')">1h</span>
          <span v-if="interval == '1h'" style="font-weight: bold">1h</span>
          |
          <span v-if="interval != '12h'" class="interval-link" @click="clickedInterval('12h')">12h</span>
          <span v-if="interval == '12h'" style="font-weight: bold">12h</span>
          |
          <span v-if="interval != '1d'" class="interval-link" @click="clickedInterval('1d')">24h</span>
          <span v-if="interval == '1d'" style="font-weight: bold">24h</span>
          |
          <span v-if="interval != '7d'" class="interval-link" @click="clickedInterval('7d')">7d</span>
          <span v-if="interval == '7d'" style="font-weight: bold">7d</span>
          |
          <span v-if="interval != '30d'" class="interval-link" @click="clickedInterval('30d')">30d</span>
          <span v-if="interval == '30d'" style="font-weight: bold">30d</span>
          |
          <span v-if="interval != '1y'" class="interval-link" @click="clickedInterval('1y')">1y</span>
          <span v-if="interval == '1y'" style="font-weight: bold">1y</span>
        </td>
      </tr>
    </table>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-else-if="isLoading && reasons.length == 0" style="text-align: center">
    <div class="spinner-border spinner-border-lg text-primary" role="status" style="margin-top: 200px">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="reasons.length > 0" v-bind:style="{opacity: isLoading ? 0.7 : 1.0}">
    <center>
      <table style="margin-top: 20px" width="90%">
        <tr>
          <td width="33%" style="font-size:20px; font-weight: bold;" align="center">
            <table cellpadding="3" v-c-tooltip="{content: tooltipTotalBlockedAllowed(), placement: 'top'}">
              <tr>
                <td align="center"><span class="badge badge-pill badge-light">{{ percentBlocked() }}%</span></td>
                <td align="center"><span class="badge badge-pill badge-light">{{ percentAllowed() }}%</span></td>
              </tr>
              <tr>
                <td align="center"><span class="badge badge-pill badge-danger">Blocked</span></td>
                <td align="center"><span class="badge badge-pill badge-success">Allowed</span></td>
              </tr>
            </table>
          </td>
          <td width="33%" align="center">
            <vc-donut foreground="grey" :size="190" unit="px" :thickness="30" :sections="totalChartData()" :total="100" :start-angle="0">
              <div style="font-weight: bold; font-size: 20px">{{ totalMessages().toLocaleString() }}</div>
              <div style="font-weight: bold; font-size: 15px">Messages</div>
            </vc-donut>
          </td>
          <td width="33%" style="font-size:15px; font-weight: bold;" align="center">
            <table>
              <tr v-for="reason in reasonsBreakdown()" v-c-tooltip="{content: tooltipBreakdown(reason), placement: 'left'}">
                <td align="right"><span class="badge badge-pill badge-light">{{ Math.round(reason.percent) }}%</span></td>
                <td align="left">
                  <span v-if="reason.blocked" class="badge badge-pill badge-danger">{{ reason.reason }}</span>
                  <span v-else class="badge badge-pill badge-success">{{ reason.reason }}</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <hr>

      <table style="margin-top: 20px; margin-bottom: 10px" width="90%">
        <tr>
          <td v-for="reason in reasons" align="center">
            <vc-donut foreground="grey" :size="135" unit="px" :thickness="30" :sections="reasonChartData(reason)" :total="100" :start-angle="0" v-c-tooltip="{content: tooltipReason(reason), placement: 'top'}">
              <div style="font-weight: bold; font-size: 12px">{{ reasonTitle(reason) }}</div>
              <div style="font-weight: bold; font-size: 20px">{{ reasonCount(reason).toLocaleString() }}</div>
              <span class="badge badge-pill badge-light">{{ reasonPercent(reason) }}%</span>
            </vc-donut>
          </td>
        </tr>
      </table>

    </center>
  </div>
</div>
</template>


<script>
export default {
  name: 'Stats',
  data() {
    return {
      errorMessage: null,
      isLoading: false,
      interval: "1d",
      reasons: []
    };
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedInterval(interval) {
      if (this.isLoading) {
        return;
      }
      this.interval = interval;
      this.loadReasons();
    },
    //--------------------------------------------------------------------------
    loadReasons() {
      if (this.isLoading) {
        return;
      } else {
        this.errorMessage = null;
        this.isLoading = true;
      }

      // Fetch the heatmap
      var that = this;
      var method = "GET";
      var url = "https://sms-filter.platform.robocalls.ai/stats/reasons?interval=" + this.interval;
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isLoading = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isLoading = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isLoading = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      // Make sure we have reasons
      if (!response || !response.reasons) {
        this.errorMessage = "Server did not return any data";
        return;
      }
      this.reasons = response.reasons;
    },
    //--------------------------------------------------------------------------
    reasonCount(reason) {
      if (!reason.reason) {
        return 0;
      }
      var total = reason.blocked + reason.allowed;
      return total;
    },
    //--------------------------------------------------------------------------
    reasonChartData(reason) {
      var total = this.reasonCount(reason);
      var allowed = 0;
      var blocked = 0;
      if (total > 0) {
        allowed = Math.round(reason.allowed / total * 100);
        blocked = 100 - allowed;
      }

      return [{
          label: 'Allowed',
          value: allowed,
          color: '#00b300'
        },
        {
          label: 'Blocked',
          value: blocked,
          color: '#e60000'
        }
      ];
    },
    //--------------------------------------------------------------------------
    reasonPercent(reason) {
      var total = 0;
      for (var x = 0; x < this.reasons.length; x++) {
        total += this.reasons[x].blocked + this.reasons[x].allowed;
      }
      if (total == 0) {
        return 0;
      } else {
        var percent = this.reasonCount(reason) / total;
        return Math.round(percent * 100);
      }
    },
    //--------------------------------------------------------------------------
    reasonTitle(reason) {
      if (!reason.reason) {
        return reason;
      }

      switch (reason.reason.toLowerCase()) {
        case "ml_model":
          return "ML Model";
        case "domain_reputation":
          return "Domain";
        case "sender_override":
          return "Sender";
        case "sender_hopping":
          return "Sender Hopping";
        case "internal_error":
          return "Error";
        default:
          return reason.reason.toLowerCase()
      }
    },
    //--------------------------------------------------------------------------
    percentAllowed() {
      return 100 - this.percentBlocked();
    },
    //--------------------------------------------------------------------------
    percentBlocked() {
      var total = 0;
      var totalBlocked = 0;
      for (var x = 0; x < this.reasons.length; x++) {
        total += this.reasons[x].blocked + this.reasons[x].allowed;
        totalBlocked += this.reasons[x].blocked;
      }
      if (total > 0) {
        return Math.round(totalBlocked / total * 100);
      } else {
        return 0;
      }
    },
    //--------------------------------------------------------------------------
    reasonsBreakdown() {
      var total = this.totalMessages();
      var breakdown = [];
      if (total == 0) {
        return breakdown;
      }
      for (var x = 0; x < this.reasons.length; x++) {
        var amount = this.reasons[x].allowed / total * 100;
        if (amount > 0) {
          breakdown.push({
            percent: amount,
            blocked: false,
            reason: this.reasonTitle(this.reasons[x])
          })
        }
        amount = this.reasons[x].blocked / total * 100;
        if (amount > 0) {
          breakdown.push({
            percent: amount,
            blocked: true,
            reason: this.reasonTitle(this.reasons[x])
          })
        }
      }
      breakdown.sort(function(a, b) {
        if (a.percent > b.percent) {
          return -1
        } else if (a.percent < b.percent) {
          return 1
        } else {
          return 0;
        }
      });
      return breakdown;
    },
    //--------------------------------------------------------------------------
    totalChartData() {
      return [{
          label: 'Allowed',
          value: this.percentAllowed(),
          color: '#00b300'
        },
        {
          label: 'Blocked',
          value: this.percentBlocked(),
          color: '#e60000'
        }
      ];
    },
    //--------------------------------------------------------------------------
    totalMessages() {
      var total = 0;
      for (var x = 0; x < this.reasons.length; x++) {
        total += this.reasons[x].blocked + this.reasons[x].allowed;
      }
      return total;
    },
    //--------------------------------------------------------------------------
    tooltipBreakdown(reason) {
      var message = "Out of the " + this.totalMessages().toLocaleString() + " messages we received, " + Math.round(reason.percent) + "% were ";
      if (reason.blocked) {
        message += "blocked";
      } else {
        message += "allowed";
      }
      message += " because of the " + reason.reason;

      return message;
    },
    //--------------------------------------------------------------------------
    tooltipReason(reason) {
      var message = this.reasonPercent(reason) + "% of the messages were decided based on the " + this.reasonTitle(reason);
      return message;
    },
    //--------------------------------------------------------------------------
    tooltipTotalBlockedAllowed() {
      var message = "Out of the " + this.totalMessages().toLocaleString() + " messages we received, " + this.percentBlocked() + "% were blocked and " + this.percentAllowed() + "% were allowed";
      return message;
    },
  },
  mounted() {
    this.loadReasons();
  },
}
</script>
